.home-page {
  .app-routes {
    display: grid;
    row-gap: frem(1.25);
    column-gap: frem(1.25);
    grid-template-columns: repeat(1, 1fr);

    @include responsive("tablet") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive("pc") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include responsive("large-screen") {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
