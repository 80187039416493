.photo-uploader {
  border-radius: 8px;


  cursor: pointer;

  .upload-area {
   

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    gap: frem(0.5);

    &:hover {
      background-color: $primary-light;
    }
  }

  .file-preview {
    padding: frem(1);

    width: 100%;

    .thumbnail {

       height: 100%;
        img {



          width: 100%;
          height: 100%;
          object-fit: cover;

        }

    }

    .actions {
      display: flex;
      gap: frem(0.25);
      margin-top: frem(0.5);
    }
  }
}
