.news-metadata-form-container {


  .header {
  }
  .main {
    

    .employee-metadata-form {
      padding: frem(1);
    }
  }

  .actions {
    display: flex;
    gap: 20px;
  }
}
