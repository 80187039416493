.kpi-review-card {
  position: relative;
  background-color: white;
  border-radius: 4px;
  border: 2px solid $gray-200;
  padding: frem(1);
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1);

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-300, 0.4);
    z-index: 10;
  }

  h4 {
    width: 85%;
  }

  .review-form {
    display: flex;
    flex-direction: column;
    gap: frem(1);

    .slider {
      width: 80%;
    }

    textarea {
      resize: none;
    }
  }
}
