.department-database-page {
  display: flex;
  flex-direction: column;
  gap: frem(1.5);

@media screen and (max-width: 1023px){
  display: none;
} 


  &__header {


    .search-box {
      border: 1px solid $gray-500;
      border-radius: 6px;
      font-size: frem(0.75) !important;
    }

    .department-database-controls {
    

      .add-user-btn {
        display: flex;
        align-items: center;
        gap: frem(0.75);
        text-wrap: nowrap;
      }
    }
  }

  &__data {
    border: 1px solid $gray-300;
    border-radius: 6px;
    .departments-table {

      width: 100%;
      border-collapse: collapse;
      border-radius: 6px;
      border-spacing: 0;
      background: white;
      max-height: 70vh;

      thead {
        background-color: $gray-200;

        th {
          &:first-child {
            border-top-left-radius: 6px;
          }

          &:first-child {
            border-top-right-radius: 6px;
          }
          padding: frem(1);
        }
      }

      tbody {


        td {
          padding: frem(1);

          p {

            color: #00A3FF;
            font-weight: 600;
            line-height: 16px;
            cursor: pointer; /* 100% */
          }
        }
      }
    }
  }
}

.department-database-no-content{
  display: none;

  @media screen and (max-width: 1023px){
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: frem(1.5);
    
  }
}
