.faq-button {
    display: flex;
    padding: frem(0.5);
    justify-content: center;
    align-items: center;
    gap: frem(0.65);
    border-radius: 6px;
    background: $gray-100;

    &:hover {
        cursor: pointer;
        background: $gray-300;
    }


    i {
        color: #343330;
        font-size: frem(1.5);
    }
}