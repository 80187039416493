.evaluation-progress-indicator {
  .side {
    display: flex;
    gap: frem(1);
    align-items: center;

    .multiline {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: frem(0.25);
    }
  }

  .seperator {
    height: frem(1);
  }
}
