.meeting-response-indicator {
  display: flex;
  gap: frem(0.25);
  .side {
    display: flex;
    gap: frem(0.25);
    align-items: center;
  }

  .seperator {
    width: frem(2);
    display: flex;
    align-items: center;

    .line {
      height: 4px;
      background-color: $gray-200;
      width: 100%;
    }
  }
}

.meeting-card {
  background-color: white;
  border-radius: 8px;
  padding: frem(1.25);

  display: flex;
  flex-direction: column;
  gap: frem(1);

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: frem(1);

    form {
      display: grid;
      grid-template-columns: 1.5fr 3fr;
      column-gap: frem(2);
      // row-gap: frem(0.5);

      .date-info {
        margin-top: frem(0.5);
      }

      textarea {
        resize: none;
      }

      .reset-btn {
        cursor: pointer;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}
