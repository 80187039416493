.photo-thumbnail {

  position: relative;


  .content {


    img {

      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .actions {
    position: absolute;
    top: -9%;
    right: -8%;
    cursor: pointer;


  }
}