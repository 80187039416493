.employee-profile-banner {
  display: flex;
  flex-direction: column;
  gap: frem(1.5);
  background-color: white;
padding-left: frem(1);
  &__loading-skeleton{
    min-height: 130px;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
  }
  .profile-card {
    padding: frem(1) frem(0);
    background-color: white;
    display: flex;
    gap: frem(1.5);
    border-radius: 8px;

    img {
      border-radius: 6px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      // gap: frem(0.5);
      align-items: left;
      text-wrap: wrap;
    }

    .edit-btn {
      position: relative;
      top: 2.5rem;
      left: -0.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.employee-profile-banner__loading-skeleton {
  min-height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}