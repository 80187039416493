.employee-kpi-planner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: frem(1);
  height: 100%;

  .kpis-container {
    // min-height: 100px;
    display: flex;
    flex-direction: column;
    gap: frem(1);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }

  .warning-text {
    display: flex;
    align-self: flex-end;
    width: fit-content;
    gap: frem(0.5);
  }
}
