.pm-cycle-item {
  display: flex;
  justify-content: space-between;
  padding: frem(0.75);
  border: 1px solid $gray-300;
  border-radius: 6px;
  cursor: pointer;

  &.focused {
    border-color: $primary-active;
  }
}
