.app-pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  font-size: 0.75rem;
  gap: 0.25rem;
}

.app-page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    &:not(.disabled) {
      background-color: #f1faff;
    }
  }

  &.active {
    background-color: #3699ff;
    color: white !important;
  }

  &.disabled {
    cursor: text;
  }
}
