.photo-upload-area {
  border-radius: 6px;
  background-color: white;
  padding: frem(1);

  display: flex;
  flex-direction: column;
  align-items: center;

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: frem(0.5);

    .photo-upload-info {
      text-align: center;
    }

    .img-container {
      text-align: center;
      position: relative;
      button {
        position: absolute;
        top: frem(-1);
        right: frem(-0.5);
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: frem(0.5);
  }
}
