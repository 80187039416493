.tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  gap: frem(1);

  .tab-item {
    // text-wrap: nowrap;
    cursor: pointer;
    color: $gray-400;
    padding: 0 frem(0.25) frem(0.5) frem(0.25);
    padding-bottom: frem(0.5);
    font-weight: 600;
    display: flex;
    gap: frem(0.5);

    &--active {
      color: $primary;

      font-weight: bold;
      border-bottom: 2px solid $primary;
    }
  }
}
