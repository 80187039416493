.employee-page {
  .input-area {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: frem(1.25);
    min-height: 0;
    /* NEW */
    min-width: 0;
    /* NEW; needed for Firefox */

    h1 {
      margin-bottom: frem(1.5);
    }

    @include responsive(mobile) {
      row-gap: frem(1.75);

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        padding-inline: frem(1);
      }
    }

    .left {
      display: flex;
      flex-direction: column;
      height: min-content;
      border-radius: 6px;
      min-height: 0;
      /* NEW */
      min-width: 0;
      /* NEW; needed for Firefox */

      background-color: white;
      padding: frem(1);

      // &__loading-skeleton{
      //   min-height: 130px;
      //   display: flex;
      //   flex-direction: column;
      //   background-color: white;
      //   justify-content: center;
      .photo-card {
        display: flex;
        gap: frem(1.5);

        img {
          border-radius: 8px;
        }

        &__info {
          display: flex;
          flex-direction: column;
          gap: frem(0.5);
          align-items: left;
          text-wrap: wrap;
        }
      }
    }

    .right {
      background-color: white;
      padding: frem(1);
      border-radius: 6px;

      .employee-info-section {
        &__loading {
          height: 430px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0px !important;

          .edit-icon {
            cursor: pointer;
            background-color: $background-primary-light;
            border-radius: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: frem(1.9);
            height: frem(1.9);

            i {
              // height: frem(1.5);
              // width: frem(1.5);
              &::before {
                font-size: frem(1);
              }
            }
          }
        }
      }

      .employee-meta-info-section {
        display: flex;
        flex-direction: column;
        gap: frem(1);
      }
      .row {
        display: flex;
        gap: frem(2.5);

        @include responsive(mobile) {
          flex-direction: column;
          gap: frem(1.5);
          width: 100%;
        }
      }

      .employee-info-form-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: frem(2);
      }
    }
  }
}
