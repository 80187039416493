.file-thumbnail {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    gap: frem(0.5);

    .filename {
      &:hover {
        color: blue !important;
      }
    }
  }
}
