.kpi-review-workspace {
  background-color: white;
  border-radius: 8px;
  padding: frem(1);
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.5);

  .kpi-list {
    display: grid;
    grid-auto-flow: row;
    row-gap: frem(1.5);
  }

  .inline-assesment-result {
    display: flex;
    width: min-content;
    text-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: frem(0.5) frem(0.75);
    border: 1px solid $gray-300;
    border-radius: 6px;

    .score {
      display: flex;
      margin-left: frem(1);
      align-items: center;
      gap: frem(0.5);
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}
