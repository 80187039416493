.router-card {
  background-color: white;

  padding: frem(1.5) frem(2);
  border-radius: 8px;
  border: 1px solid $secondary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: frem(4);

  &:hover {
    box-shadow: 1px 2px 1px 1px rgba($gray-500, 0.1);
  }

  @include responsive("mobile") {
    padding: frem(0.75) frem(1);
  }

  &__main {
    padding: frem(2.5) frem(2.25) 0;
    display: flex;
    flex-direction: column;
    gap: frem(1.5);
    align-items: center;
  }

  &__footer {
    width: 100%;
    background-color: $background-3;
    display: flex;
    align-items: center;
    padding: frem(0.25);
    border-radius: 6px;
    gap: frem(0.5);

    &.hidden {
      visibility: hidden;
    }
  }

  &--dark {
    background-color: $brand-color;

    &__footer {
      color: $gray-900;
    }
  }
}
