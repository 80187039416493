.kpi-approval-workspace {
  display: flex;
  flex-direction: column;
  gap: frem(1);
  border-radius: 8px;

  .kpis-list {
    // height: 400px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: frem(0.5);
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    .left {
      textarea {
        height: 100px;
        resize: none;
      }
    }

    .right {
      display: flex;
      gap: frem(0.5);
      justify-content: flex-end;
    }

    .revert-action {
      display: flex;
      gap: frem(0.5);
      align-items: flex-start;

      .revert-btn {
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        gap: frem(0.25);
      }
    }
  }
}
