.employee-assesment-result {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1);
  background-color: white;
  padding: frem(1.5);
  border-radius: 8px;

  .result {
    display: flex;
    justify-content: space-between;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding: frem(1);

    .right {
      display: flex;
      align-items: center;
      gap: frem(0.5);

      .badge-light-warning-second {
        color: #fff;
        background-color: #ee9d01;
      }
    }
  }
}
