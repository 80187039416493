.admin-page {

    @extend .responsive;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: frem(1.25);
    }

    .admin-page-card-item {
        margin-top: frem(1.5);
        width: calc(100% / 3 - frem(1.5));
        height: 295px;
        padding: frem(1.5) frem(2);
        justify-content: center;
        align-items: center;


        border-radius: 8px;
        border: 1px solid var(--secondary, #E1E3EA);
        background: #FFF;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: frem(1.5);

        .card-img {
            width: 50px;
            height: 50px;
        }

        .card-text {
            font-size: frem(1.25);
            line-height: frem(1.75);
        }
    }
}