.notification-stack {
  position: absolute;
  right: frem(6.25);
  bottom: frem(3);
  z-index: 100;

  display: flex;
  flex-direction: column;
  gap: frem(0.75);
}
