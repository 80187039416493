.toaster {
  position: fixed;
  bottom: frem(2);
  right: frem(2);
  padding: frem(1);
  width: 320px;
  max-width: 590px;
  border-radius: 6px;

  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  &-body {
    padding: 0 frem(0.75);
    display: flex;
    justify-content: flex-start;
    gap: frem(1);
  }
}