.news-cards  {

    .card {

        border-radius: 6px;
        border: 1px solid var(--gray-300, #E1E3EA);
        background: #FFF;
        height: 100%;
        cursor: pointer;

        .actions  {

            .btn {

                border-radius: 6px;
                background: var(--gray-200, #F4F4F4);
                padding: 7px;
            }


        }

        .card-image {

            height: 200px;

            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 6px 6px 0px 0px;     
            }
        }

        .content  {

            .title {


                color: var(--primary, #3E97FF);
            }

            .desc {

                color: var(--gray-700, #5E6278);
                font-size: 14px;
                font-weight: 600;
      


            }

            .date {


           
                color: var(--primary, var(--primary, #3E97FF));
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
        



            }

        }

   
    }
}