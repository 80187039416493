.app-navbar {
  padding: 0 frem(6.25);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  @include responsive("mobile") {
    padding: 0 frem(1);
  }

  @include responsive("tablet") {
    padding: 0 frem(2);
  }

  .left {
    .app-logo--mobile {
      display: none;
    }

    @include responsive("mobile") {
      .app-logo {
        display: none;
      }

      .app-logo--mobile {
        display: block;
      }
    }
  }

  .right {
    .navbar-actions {
      display: flex;
      align-items: center;
      gap: frem(2);

      .navbar-action-group {
        display: flex;
        align-items: center;
        gap: frem(0.5);
      }

      .logout-btn {
        color: $brand-color;
      }
    }

    .navbar-actions--mobile {
      display: none;
    }

    @include responsive("mobile-and-tablet") {
      .navbar-actions {
        display: none;
      }

      .navbar-actions--mobile {
        display: block;

        .navbar-hamburger {
          background-color: $brand-color !important;
        }
      }
    }
  }
}

.navbar-popup {
  &--hidden {
    display: none;
  }

  position: absolute;
  border: 1px solid $gray-300;
  border-radius: 8px;
  right: frem(1);
  top: frem(5);
  background-color: $background-2;
  display: flex;
  flex-direction: column;
  gap: frem(0.5);
  min-width: frem(18.5);

  &__row {
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid $gray-200;

    padding: frem(1) frem(0.75);
  }

  .row-2 {
    // display: flex;
    // flex-direction: column;
    .switch-container {
      display: flex;
      justify-content: center;
      padding: frem(0.5);
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: frem(1.5);
      color: $brand-color !important;
    }
  }

  .icon-container {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: $brand-color;
    }
  }
}
