.training-matrix-renderer {


    .top {
    
        /* Ensure the top div takes full width */

        .top-dropdown {
         
        }

        .search-box {
           
            align-self: flex-end;
            border: 1px solid $gray-400;
            border-radius: 6px;
            font-size: frem(0.75) !important;

        }
    }


    .add-new-container {
        display: flex;
        justify-content: flex-end;
        gap: frem(1);
        margin-top: frem(1);

        button {
      


        }


    }
}