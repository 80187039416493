.news-cover-banner {

  
    .image  {
      
      height: 100%;

     

         img {

          height: 100%;
          object-fit: cover;

         }
    }


}

.employee-profile-banner__loading-skeleton {
  min-height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}