.employee-assesment-thumbnail {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.5);
  background-color: white;
  border-radius: 8px;
  padding: frem(1.5);

  .assesment-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .assesment-progress {
    display: grid;
    grid-auto-flow: row;
    row-gap: frem(1);

    .evaluation-controls {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: frem(0.5);
    }
  }
}
