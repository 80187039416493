.myhr-breadcrumb {
  padding: frem(1.5) 0;

  ol li {
    a {
      all: unset;
    }
    color: $gray-600 !important;
    font-size: frem(0.75);

    &:last-child {
      color: $brand-color !important;
      font-weight: 700;
      font-size: frem(1);
    }

    cursor: pointer;
  }
}
