.pm-cycle-window {
  display: flex;
  flex-direction: column;
  gap: frem(1);
  padding: frem(1);
  border: 1px solid $secondary;
  border-radius: 8px;

  &.active {
    border-color: $gray-600;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }
  }

  .settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      gap: frem(1);
      justify-content: flex-start;
      align-items: end;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: frem(0.5);
  }
}
