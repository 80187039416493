.surveys-section {
  width: 100%;

  form {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
  }

  background-color: #fff;

  .survey-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    textarea {
      resize: none;
    }
  }
}
