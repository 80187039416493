.employee-metadata-form-container {
  display: flex;
  flex-direction: column;
  gap: frem(0.75);

  .header {
  }
  .main {
    display: flex;
    flex-direction: column;
    gap: frem(0.5);

    .employee-metadata-form {
      padding: frem(1);
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
