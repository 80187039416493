@mixin responsive($media) {
  @if $media ==mobile {
    @media only screen and (max-width: $phone) {
      @content;
    }
  }

  @if $media ==tablet {
    @media only screen and (min-width: calc($phone + 1px)) and (max-width: calc($tablet)) {
      @content;
    }
  }

  @if $media == mobile-and-tablet {
    @media only screen and (max-width: $tablet) {
      @content;
    }
  }

  @if $media ==tablet {
    @media only screen and (min-width: calc($phone + 1px)) and (max-width: calc($tablet)) {
      @content;
    }
  }

  @if $media ==pc {
    @media only screen and (min-width: calc($tablet + 1px)) and (max-width: calc($pc - 1px)) {
      @content;
    }
  }

  @if $media ==large-screen {
    @media only screen and (min-width: calc($pc + 1px)) {
      @content;
    }
  }
}
