.app-layout {
  padding: 0 frem(1) frem(1);

  @include responsive("tablet") {
    padding: 0 frem(2) frem(1);
  }

  @include responsive("pc") {
    padding: 0 frem(6.25) frem(2);
  }

  @include responsive("large-screen") {
    padding: 0 frem(6.25) frem(2);
  }
}
