.pm-cycle-manager {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.5);
  min-height: 300px;

  .pm-windows-container {
    border-radius: 8px;
    padding: frem(1);
    background-color: white;

    .pm-window-wrapper {
      padding: 0 0 frem(1) frem(1);
    }
  }

  &__loader {
    height: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
