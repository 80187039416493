.pm-window-navigator-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: frem(0.5);
}

.pm-window-navigator {
  background-color: white;
  border-radius: 6px;
  min-width: 264px;

  .window-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: frem(0.75);
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      &:not(.disabled) {
        font-weight: 600;
      }
    }
  }
}
