.employee-training-cards {


    .training-card {

    
          border: 1px solid #FAF7F0;


        .training-info {

       
            .training-name {

                line-height: 28px; 
            }

            .training-category {


                font-size: 16px;
                line-height: 20px; 
            }
        }

    .training-status {

    }

    .training-actions {


    }

    .training-meta-info {

        .obligation {

  

font-size: 16px;
line-height: 20px; 

        }
        .training-format {

  

font-size: 16px;
line-height: 20px; 
text-transform: capitalize;

        }
        
        label {

        

font-weight: 600;

        }
    }
    }
}