.render-preference-card {
  background-color: white;
  padding: frem(1.5) frem(2);
  border-radius: 8px;
  border: 1px solid $secondary;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-color: $gray-400;
  }

  .title {
  
    color: $brand-color;
  }
}
