.training-matrix-cards {

    .training {

        border-radius: 6px;
        border: 1px solid var(--gray-300, #E1E3EA);



        .training-content {
            font-size: 14px;

            .training-title {

                color: red;
            }

            .training-description {

                color: var(--gray-700, #5E6278);
                font-weight: 600;



            }

            .training-location {
                font-weight: 600;

            }

            .training-category {

                font-weight: 600;
            }
        }

        .training-actions {



            button {

                color: var(--white, #FFF);
                padding: 8px 18px !important;
                border-radius: 6px;
                background: var(--gray-800, #3F4254);
                font-size: 12px;
                line-height: 1;
                font-weight: 600;

            }
        }



    }

}