.file-uploader {
  border-radius: 8px;
  width: 50%;

  display: flex;
  gap: frem(0.5);
  align-items: center;

  cursor: pointer;

  .upload-area {
    padding: frem(1);

    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    gap: frem(0.5);

    &:hover {
      background-color: $primary-light;
    }
  }

  .file-preview {
    padding: frem(1);

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .thumbnail {
      display: flex;
      gap: frem(0.5);
      align-items: center;
      text-wrap: wrap;
    }

    .actions {
      display: flex;
      gap: frem(0.25);
    }
  }
}
