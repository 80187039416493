.employee-assesment-page {
  display: grid;
  grid-template-columns: 4fr 8fr;
  column-gap: frem(3);

  min-height: 0;
  min-width: 0;

  .assesment-status-section {
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */

    display: grid;
    grid-auto-flow: row;
    row-gap: frem(1.5);
    height: min-content;
  }

  .workspace {
    display: flex;
    flex-direction: column;
    gap: frem(1.5);
    align-items: flex-start;
  }
}
