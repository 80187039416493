.auth-page {
  @include responsive(mobile) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-bg-img {
    @include responsive(mobile) {
      display: none;
    }
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: fill;
    object-position: center;
  }

  &-bg-overlay {
    @include responsive(mobile) {
      display: none;
    }
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#3f4254, 0.4);
  }

  .login-form__container {
    @include responsive(mobile) {
      border-radius: 0px;
      margin: 0px;
      width: 100%;
      padding: frem(2.5) frem(1);
    }

    position: relative;
    padding: frem(5) frem(3.75);
    margin-right: frem(6.25);
    background-color: white;
    z-index: 1000;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: frem(2.5);
    width: 550px;

    .login-form__form-header {
      display: flex;
      flex-direction: column;
      gap: frem(1.5);

      .subtitle {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: frem(0.5);
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: frem(1.5);

      .form-control-group {
        display: flex;
        flex-direction: column;
        gap: frem(1.25);
      }

      .form-field {
        display: flex;
        flex-direction: column;
        gap: frem(1);
        align-items: flex-end;

        &.error-field {
          align-items: flex-start;
        }
      }
    }

    .login-form__submit-btn {
      background-color: $brand-color;

      &:hover {
        background-color: darken($brand-color, 3);
        font-weight: 600;
      }
    }
  }

  .password-reset-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: frem(2.5);

    &__text {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
}
