.kpi-distortion-viewer {
  display: flex;
  padding: frem(1);
  flex-direction: column;
  gap: frem(0.5);
  border: 1px solid $gray-200;
  border-radius: 6px;

  .results {
    display: flex;
    flex-direction: column;
    gap: frem(0.25);

    .result-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .indicator {
        display: flex;
        align-items: center;
        gap: frem(0.5);
      }
    }
  }
}
