.pm-cycles-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: frem(1);
  border-radius: 6px;
  gap: frem(1);

  .pm-cycles-list-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .pm-cycles-list {
    display: none;
  }

  @include responsive("pc") {
    .pm-cycles-dropdown {
      display: none;
    }

    .pm-cycles-list {
      display: grid;
      list-style: none;
      padding: 0;
      margin: 0;
      grid-auto-flow: row;
      row-gap: frem(0.75);
    }
  }
  @include responsive("large-screen") {
    .pm-cycles-dropdown {
      display: none;
    }
    .pm-cycles-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      grid-auto-flow: row;
      row-gap: frem(0.75);
    }
  }
}
