.pm-page-layout {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.5);

  @include responsive("pc") {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: frem(2.5);
  }

  @include responsive("large-screen") {
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: frem(2.5);
  }

  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */

  .pm-cycles-section {
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */
  }

  .pm-cycle-workspace {
    display: flex;
    flex-direction: column;
    gap: frem(1.25);
    width: 0;
    min-width: 100%;
  }
}
