.employee-selection-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  gap: frem(1.5);

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .employees-selector {
    display: flex;
    flex-direction: column;
    gap: frem(1);

    .employees-list {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 300px;
      overflow-y: scroll;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: frem(1);

        &:not(:last-child) {
          margin-bottom: frem(1);
        }
        &.selected-employee {
          justify-content: space-between;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}
