.vertical-timeline-container {
  display: grid;
  grid-template-columns: min-content auto;

  .liner {
    padding: relative;
    overflow: hidden;

    .circle {
      position: relative;
      border: 4px solid $gray-500;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      background-color: white;

      &:after {
        position: absolute;
        border: 3px solid $gray-200;
        width: 0;
        display: block;
        content: "";
        z-index: 1;
        top: 20px;
        height: 100vh;
        left: 3px;
      }

      &.last {
        &:after {
          display: none;
        }
      }

      &.current {
        border-color: $success-active;
      }
    }
  }
}
