.pm-cycles-browser {
  display: flex;
  flex-direction: column;
  gap: frem(1.5);
}

.performance-management-page {
  display: flex;
  flex-direction: column;
  gap: frem(1.25);
}
