.ambigous-training-page {


  .training-router-card {
    background-color: white;
  
    padding: frem(1.5) frem(2);
    border-radius: 8px;
    border: 1px solid $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: frem(4); 
  
    &:hover {
      box-shadow: 1px 2px 1px 1px rgba($gray-500, 0.1);
    }
  
    .title {

      color: $brand-color;
    }
  }
}