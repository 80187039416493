.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .loading-spinner {
    display: flex;
    flex-direction: column;
    flex-direction: center;
    align-items: center;
  }
}
