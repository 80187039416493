.kpi-review-slider {
  .range-identifiers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .identifier-region {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: frem(0.5) 0;

      .identifier {
        color: $gray-700;
        &.is-active {
          font-weight: 700;
          color: $gray-800;
        }
      }
    }
  }

  input {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      #d1263c 25%,
      #ee9d01 25% 50%,
      #ffc657 50% 75%,
      #50cd89 75%
    );
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    /* --------------------------- webkit browsers */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      box-sizing: border-box;
      width: 22px;
      height: 22px;
      border-radius: 20px;
      border: 4px solid white;
      z-index: 10;
      background-color: $primary;
      overflow: visible;
      cursor: pointer;

      &::before {
        position: absolute;
        top: -frem(2);
        left: 11px;
        height: frem(1);
        width: frem(2);
        background-color: $gray-800;
      }
    }

    // /* -------------------------- Firefox */
    &::-moz-range-thumb {
      -moz-appearance: none;
      width: 22px;
      height: 22px;
      border-radius: 20px;
      border: 4px solid white;
      z-index: 10;
      background-color: $primary;
      overflow: visible;
      cursor: pointer;
    }
  }
  .slider-ticks {
    display: flex;
    justify-content: space-between;

    .tick {
      &.is-active {
        font-weight: 700;
      }
    }
  }
}
