.faq-page {
    background: white;
    display: flex;
    padding: frem(1);
    flex-direction: column;
    align-items: flex-start;
    gap: frem(1);

    .faq-header {

        color: var(--gray-800, var(--gray-800, #3F4254));
        font-size: frem(1.25);
        font-style: normal;
        font-weight: 600;
        line-height: frem(1.25);


    }

    .faq-header-text {
        color: var(--gray-600, var(--gray-600, #7E8299));

        font-size: frem(1.1);
        font-style: normal;
        line-height: frem(1.5);
     
    }

    .faq-item {
        display: flex;
        gap: frem(1.25);
        align-items: flex-start;
        font-size: frem(0.9);
        align-self: stretch;
        flex-direction: row;

        i {
            font-size: frem(1.7);
            color: $brand-color
        }

        ;

        .faq-item-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: frem(1.25);

            padding-top: frem(0.25);

            .faq-item-text-title {
                display: flex;
                gap: frem(1.25);
                text-align: center;
                color: var(--gray-700, var(--gray-700, #5E6278));


                font-size: frem(1);
                font-style: normal;
                font-weight: 600;
                line-height: frem(1);

            }

            .faq-item-text-description {
                color: var(--gray-500, var(--gray-500, #A1A5B7));
                font-size: frem(0.9);
                font-style: normal;
                line-height: frem(1.2);

            }

            .closed {
                display: none;
            }
        }
    }
}