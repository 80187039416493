.employee-training-renderer {


        .search-container {
            .search-box {
           
     
                border: 1px solid $gray-400;
                border-radius: 6px;
                font-size: frem(0.75) !important;
    
            }

        }

        .top-dropdown {

            width: 173px;
        }
      
    


}