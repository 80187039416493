.employee-thumbnail {
  display: flex;
  text-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: frem(0.5);

  .left {
    img {
      border-radius: 6px;
    }
  }
}
