.news-details-form {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.25);


  textarea {

    min-height: 236px;
  }


  .form-action-group {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: frem(1);

    .draft {

      align-self: center;
  }
  }
}
