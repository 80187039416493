.employee-experience-form-container {
  padding: frem(1);
  border: 1px solid $gray-200;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: frem(1);

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}

.employee-experience-form {
  display: flex;
  flex-direction: column;
  gap: frem(1);
}

.employee-experience-thumbnail {
  display: flex;
  justify-content: space-between;
  padding: 0 frem(0.5);
}
