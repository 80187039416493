.app-mode-switch {
  display: flex;
  gap: frem(0.5);
  align-items: center;
  font-weight: bold;

  input {
    background-color: $primary !important;

    &:checked {
      background-color: $gray-900 !important;
    }
  }

  .mode--active {

    color: $gray-900 !important;
  }
}