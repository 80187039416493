.dropdown-container {
  display: grid;
  grid-auto-flow: row;
  position: relative;

  .dropdown-selected {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    column-gap: frem(0.5);
  }

  .dropdown-items {
    &.collapsed {
      display: none;
    }
    width: 100%;
    background-color: white;
    position: absolute;
    top: frem(3);
    border: 1px solid $gray-200;
    border-radius: 4px;
  }
}
