.employee-evaluations-list-container {
  display: flex;
  flex-direction: column;
  gap: frem(1);
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: frem(0.75);

    .filters {
      display: flex;
      justify-content: flex-start;
      gap: frem(1);
    }
  }

  .main {
    background-color: white;
    border-radius: 8px;
    padding: frem(1);
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: frem(1);
  }
}
