.profile-badge {
  .content {
    display: flex;
    align-items: center;
    gap: frem(1);

    border: 1px solid $gray-300;

    img {
      object-fit: fill;
      border-radius: 50%;
    }
  }

  .content--mobile {
    display: none;
  }

  @include responsive("mobile-and-tablet") {
    .content {
      display: none;
    }

    .content--mobile {
      display: flex;
      align-items: center;
      gap: frem(1);

      img {
        border-radius: 4px;
      }

      .info {
        h4 {
          margin-bottom: frem(0.5);
        }
      }
    }
  }
}
