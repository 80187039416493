.news-and-events-renderer {
    display: flex;
    flex-direction: column;

    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /* Ensure the top div takes full width */

        .top-dropdown {
            width: 400px;
        }

        .search-box {
            width: 400px;
            align-self: flex-end;
            border: 1px solid $gray-400;
            border-radius: 6px;
            font-size: frem(0.75) !important;

        }
    }


    .add-new-container {
        display: flex;
        justify-content: flex-end;
        gap: frem(1);
        margin-top: frem(1);

        button {
            &:hover {

                a {

                    color: #fff;

                }


            }


        }


    }
}