.filter-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;

    .tab {
        color: $gray-400;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 8px;
        cursor: pointer;
    }

    .active {
        color: $primary;
        border-bottom: 2px solid $primary;

    }
}