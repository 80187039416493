.pm-cycle-general-settings {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1);
  background-color: white;
  padding: frem(1);
  border-radius: 8px;
  max-width: 462px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}
