.training-card-item {

    border-radius: 8px;
    border: 1px solid #FAF7F0;
    background: #FFF;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline: 32px;
    padding-block: 12px;

    .left-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        .left-img {
            width: 40px;
            height: 40px;
            border-radius: 9px;

        }

        .left-texts {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .text1 {
                color: var(--gray-900, #181C32);
                
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .text2 {
                color: var(--gray-600, #7E8299);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                margin-bottom: 0px;
            }
        }
    }



    .badge {

    
        font-size: 12px;
      
        font-weight: 600;
        line-height: 12px;
        padding: 7px;
    }


    button {

        color: var(--white, #FFF);
        padding: 8px 18px !important;
        border-radius: 6px;
        background: var(--gray-800, #3F4254);


        font-size: 12px;
        line-height: 1;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;


    }
}