.add-training-page  {


    .add-training {

        .training-details-form {


        }
    }

    .add-position {

        .positon-header {

                
        }
        .search-box {
        
            border-radius: 6px;
            background-color: $gray-200 !important;
       
          }

        .positon-details-form {

            height: 200px;



            .title {

                color: var(--gray-800, var(--gray-800, #3F4254));
                font-size: 16px;
                font-weight: 600;
                line-height: 18px; /* 112.5% */
                letter-spacing: -0.16px;
            }
        }
    }
}