.employee-evaluation-card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid $gray-300;
  padding: frem(0.75) frem(2);

  &:hover {
    border-color: $gray-500;
  }

  .contents {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    text-wrap: nowrap;

    .actions {
      display: flex;
      justify-content: flex-end;

      &__hr {
        flex-direction: column;
        gap: frem(1);
        align-items: flex-end;
      }
    }
  }
}
