.appraisal-timeline-header {
  .timeline-dropdown {
    display: none;
  }

  .timeline {
    display: grid;
    grid-auto-flow: column;
    .timeline-badge {
      display: inline-flex;
      flex-direction: column;
      background-color: white;
      border-radius: 6px;
      padding: frem(0.75);
      width: 200px;
      flex-wrap: nowrap;
      cursor: pointer;
      border: 1px solid transparent;
      &:hover,
      &.active {
        &:not(.disabled) {
          border: 1px solid $gray-300;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  @include responsive("mobile-and-tablet") {
    .timeline-dropdown {
      display: block;
    }
    .timeline {
      display: none;
    }
  }
}
