$phone: 475px;
$tablet: 768px;
$pc: 1024px;

$brem: 16px;

.text-brand-1 {
  color: $brand-color;
}

.search-box {
  border: 1px solid $gray-300;
  border-radius: 6px;
  font-size: frem(0.75) !important;
}

.info-box {
  display: flex;
  justify-content: flex-start;
  width: min-content;
  gap: 4px;
}
