.confirmation-modal {
  width: 378px;
  display: flex;
  flex-direction: column;
  gap: frem(1);

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: frem(0.5);
    border-bottom: 1px solid $gray-300;

    .cancel-btn {
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    gap: frem(0.5);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: frem(0.5);
  }
}
