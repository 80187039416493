.kpi-form-container {
  display: flex;
  flex-direction: column;
  height: min-content;

  &.focused {
    gap: frem(1.5);
  }

  background-color: white;
  padding: frem(1.25);
  border-radius: 8px;

  .kpi-form-thumbnail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;

    &.hidden {
      display: none;
    }
  }

  .kpi-form {
    display: grid;
    grid-auto-flow: row;
    row-gap: frem(1);
    height: auto;
    visibility: visible;
  
    &.hidden {
      visibility: hidden;
      height: 0px;
      overflow: hidden;
    }
  
   
    .rating-form {

      .rating-wrapper {
        
        .expectation-title {
          font-size: 12px;
          font-weight: 600;
          text-transform: capitalize;
        }

        &:nth-of-type(1) {
          .expectation-title {
            color: $brand-color;

          
          }
        }
        &:nth-of-type(2) {
          .expectation-title {
            color: var(--warning, #FFA800);
          }
        }
        &:nth-of-type(3) {
          .expectation-title {
            color: #FFD789;
          }
        }
        &:nth-of-type(4) {
          .expectation-title {
            color: var(--success, #50CD89);

          }
        }
    
        .score-wrapper {
          text-align: center;
    
          .score-heading {
            font-weight: 600;
            line-height: 16px;
            color: var(--gray-900, #181C32);
            border: 1px solid #E1E3EA;
            background: var(--gray-200, #F4F4F4);
            padding: 8px;
          }
    
          .score-value {
            padding: 10px;
            justify-content: center;
            border: 1px solid #E1E3EA;
            color: var(--gray-900, #181C32);
            font-weight: 600;
          }
    
          .score-first {
            margin-bottom: 24px;
          }
        }
      }
    }



  }

  .actions {
    display: flex;
    justify-content: space-between;

    &.hidden {
      visibility: hidden;
      height: 0px;
    }

    .left,
    .right {
      display: flex;
      gap: frem(0.5);
    }
  }
}



