.employee-details-form {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1.25);

  .form-action-group {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: frem(1);
  }
}
