.meeting-planner {
  display: grid;
  grid-auto-flow: row;
  row-gap: frem(1);

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .meeting-list {
    display: grid;
    grid-auto-flow: row;
    row-gap: frem(1);
  }
}
