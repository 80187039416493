.input-w-icon-container {
  display: flex;
  gap: frem(0.5);
  align-items: center;
  justify-content: flex-start;

  background-color: white;

  input {
    padding: 0 !important;
    font: inherit !important;
  }
}
