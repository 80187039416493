@import "./_lib/styles/_metronic/assets/fonticon/fonticon.css";
@import "./_lib/styles/_metronic/assets/keenicons/duotone/style.css";
@import "./_lib/styles/_metronic/assets/keenicons/outline/style.css";
@import "./_lib/styles/_metronic/assets/keenicons/solid/style.css";
@import "./_lib/styles/_metronic/assets/sass/style.scss";
@import "./_lib/styles/_metronic/assets/sass/plugins.scss";
@import "./_lib/styles/_metronic/assets/sass/style.react.scss";

@import "./_lib/styles/index.scss";

body {
  box-sizing: border-box;
}

.app-layout {
  // @extend .responsive;
  background-color: $background-2;
  background-repeat: repeat;
  overflow-y: auto;

  &[data-hidden="true"] {
    overflow: hidden;
  }
}
