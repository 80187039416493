.fluid-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: rgba(#b5b5c3, 0.3);

  .fluid-modal-contents {
    position: fixed;
    padding: frem(1);
    border-radius: 6px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
}
