.language-selector-container {
  position: relative;

  .btn-selector--mobile {
    display: none;
    width: 100%;
  }

  @include responsive(mobile-and-tablet) {
    .btn-selector {
      display: none;
    }

    .btn-selector--mobile {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.language-selector {
  &[data-hidden="true"] {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }

  position: absolute;
  opacity: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: frem(3);
  right: frem(0.5);

  border: 1px solid $gray-300;
  border-radius: 6px;

  li {
    width: 100%;
    text-align: left;
    text-wrap: nowrap;
  }
}
