.reporting-container {
  display: flex;
  flex-direction: column;
  gap: frem(1.5);
  background-color: white;
  padding: frem(1);

  .chart-container {
    width: 100%;
    height: 100%;
    padding: frem(1);
    border-radius: 12px;
    border: 1px solid $gray-200;
  }

  .assesment-results-container {
    padding: frem(1) frem(1.5);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .assesment-result {
    display: flex;
    flex-direction: column;
    gap: frem(0.5);
  }
}
