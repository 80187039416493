.announcements-modal {

    .slider  {

      img {

            width: 100%;
            max-height: 500px;
            object-fit: cover;
            border-radius: 6px;
      }
    }

    .content {

        .title {
          
          color: var(--primary, #3E97FF);

        }
        .description {

          color: var(--gray-500, var(--gray-500, #A1A5B7));


          
            font-weight: 600;
            font-size: 14px ;
         

        }
     
    }

    .event-modal-meta-data {

      font-size: 14px;

          .event-meta-item {
            display: flex;
            align-items: center;



                .meta-icon {

                  color: var(--gray-700, #5E6278);
                }

                .meta-text {
                  color: var(--primary, var(--primary, #3E97FF));
                  font-size: 14px;
                  font-weight: 600;
                

                }
          }
    }



    .thumbnails {

      img {


        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
}

.news-date  {
  
  display: flex;
  justify-content: flex-end;

    time {

      padding: 7px;

      color: var(--primary, var(--primary, #3E97FF));
      font-size: 12px;

      border-radius: 4px;
      background: var(--primary-light, #EEF6FF);

    }
}


}
