.nv-fw {
  &-bold {
    font-weight: 700;
  }

  &-semibold {
    font-weight: 600;
  }

  &-medium {
    font-weight: 500;
  }

  &-regular {
    font-weight: 400;
  }
}

.nv {
  &-heading {
    /* Use this to specify large text on the screen. */
    &-display {
      @extend .nv-fw-bold;
      font-size: frem(3);
      line-height: frem(4);
      margin: 0px;
    }

    /* Use this to specify page, or very big section titles. */
    &-1 {
      @extend .nv-fw-bold;
      font-size: frem(2);
      line-height: frem(2.5);
      margin: 0px;
    }

    /* Use this to specify section titles. */
    &-2 {
      @extend .nv-fw-semibold;
      font-size: frem(1.5);
      line-height: frem(2);
      margin: 0px;
    }

    /* Use this to specify subtitles. */
    &-3 {
      @extend .nv-fw-semibold;
      font-size: frem(1.25);
      line-height: frem(1.75);
      margin: 0px;
    }

    /* Use this to specify headers of an equivalent body text. E.g headers of list items.  */
    &-4 {
      @extend .nv-fw-semibold;
      font-size: frem(1);
      line-height: frem(1.5);
      margin: 0px;
    }

    /* Use this to specify headers of an equivalent body text. E.g headers of list items. */
    &-5 {
      @extend .nv-fw-semibold;
      font-size: 0.75em;
      line-height: frem(1.25);
      margin: 0px;
    }
  }

  &-body {
    /* DEFAULT. Use this for regular body texts (e.g paragraphs -> news, faq, descriptions etc.)*/
    font-size: frem(1);
    line-height: frem(1.25);

    /* Use this as replacement of normal body text in smaller screens. */
    &-2 {
      font-size: frem(0.875);
    }

    /* Use this when design text within alerts, messages, notifications, and footers. */
    &-sm {
      font-size: frem(0.75);
      line-height: frem(1);
    }
  }

  &-ui {
    font-size: frem(0.875);
    line-height: frem(0.875);
    font-weight: 600;

    &-sm {
      font-size: frem(0.75);
      line-height: frem(0.75);
    }
  }
}
